$(function() {
  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $("#page-top").fadeOut();
    } else {
      $("#page-top").fadeIn();
    }
  }
  $(window).on("load scroll", displayToTop);
  //スムーススクロール
  $('#page-top a').smoothScroll({
    offset: -80
  });

  // ナビゲーション
  $('.menu-trigger').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).next('nav').removeClass('open');
      $('.overlay').removeClass('open');
      $('body').removeClass('sp-menu-open');
    } else {
      $(this).addClass('active');
      $(this).next('nav').addClass('open');
      $('.overlay').addClass('open');
      $('body').addClass('sp-menu-open');
    }
  });
  $('.overlay').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu-trigger').removeClass('active');
      $('header nav').removeClass('open');
      $('body').removeClass('sp-menu-open');
      $('ul#gnav .pulldown').removeClass('spread');
    }
  });
  $('nav ul#gnav > li > div.icon-down').on('click',function(){
    if($('+ .pulldown',this).hasClass('spread')){
      $('+ .pulldown',this).removeClass('spread');
      $('i',this).css('transform','rotateX(0)');
    } else {
      $('+ .pulldown',this).addClass('spread');
      $('i',this).css('transform','rotateX(180deg)');
    }
  });
  $('nav ul#gnav > li > div.d-none').on('click',function(){
    if($(this).hasClass('nav-current')){
      $(this).removeClass('nav-current');
    } else {
      $(this).addClass('nav-current');
    }
    if($('+.icon-down + .pulldown',this).hasClass('spread')){
      $(this).removeClass('nav-current');
      $('+.icon-down + .pulldown',this).removeClass('spread');
    } else {
      $(this).addClass('nav-current');
      $('+.icon-down + .pulldown',this).addClass('spread');
    }
  });

  // ホーム：スライダー
  if ($('body').hasClass('home')){
    $('.slider').slick({
      autoplay:true,
      autoplaySpeed:2500,
      pauseOnHover: false,
      speed: 1500,
      centerMode: true,
      variableWidth: true,
      arrows:true,
      dots: true,
      responsive: [{
        breakpoint: 767,
        settings: {
          centerPadding:'0',
          slidesToShow: 1
        }
      }]
    });
    var count = 36;
    $('#topics .topic').each(function(){
      var assetTitle = $('h3', this).text();
      var assetTitleLength = assetTitle.length;
      if(assetTitleLength>count){
        var showAssetText = assetTitle.substring(0, count);
        var insertAssetText = showAssetText += '…';
        $('h3', this).text(insertAssetText);
      }
    });
  }
  if ($('body').hasClass('results-detail')){
    $("#movie-photo iframe[src*='youtube.com']").each(function() {
    	$(this).wrap("<div class=\"iframe\"></div>");
    });
    $('#contents img').removeAttr('width height style').addClass('img-fluid');
    $('#images img, #movie-photo img').unwrap('p');
    $('#detail .floorplan a').SmartPhoto({
      nav:false
    });
    $('#images img').each(function(){
      var imgURL = $(this).attr('src');
      $(this).wrap('<div><a href="'+imgURL+'" data-group=\"gallery\" class=\"gallery\"></a></div>');
    })
    $('#images .gallery').SmartPhoto();
    var imageNum = $('#images > div').length;
    $('#images > div:nth-child(4n) a').append('<div class="over"><strong><span>' + imageNum + '</span>枚</strong>ギャラリーを見る</div>');
    $('#a3DTHETA blockquote').attr('data-width','100%');
    if($(window).width()>767){
      $('#a3DTHETA blockquote').attr('data-height','600');
    } else {
      $('#a3DTHETA blockquote').attr('data-height','320');
    }
  }
  if ($('body').hasClass('news-list')){
    var count = 36;
    $('.main .topic').each(function(){
      var topicTitle = $('h3 a',this).text();
      var topicTitleLength = topicTitle.length;
      if(topicTitleLength>count){
        var showText = topicTitle.substring(0, count);
        var insertText = showText += '…';
        $('h3 a', this).text(insertText);
      }
    });
    $('.side .widget-recent-entries ul li').each(function(){
      var postTitle = $('span.post-title',this).text();
      var postTitleLength = postTitle.length;
      if(postTitleLength>count){
        var showPostText = postTitle.substring(0, count);
        var insertPostText = showPostText += '…';
        $('span.post-title', this).text(insertPostText);
      }
    });
  }
  if ($('body').hasClass('news-load')){
    $('.side').load('/news/ .side');
  }

  if ($('body').hasClass('results-pager')){
    if ($('#results .assets').length) {
      var pagernum = $('#results .assets').length;
      console.log(pagernum);
      if(pagernum>1){
        $('#nav-prev span').hide();
        $('#nav-next span').addClass('index-2');
        for (var i=1; i<pagernum+1; i++) {
          var pager = $('<span class="pager pager-'+i+'">'+i+'</span><span> ｜ </span>');
          $('#nav-pager').append(pager);
          $('#nav-pager .pager-1').addClass('active');
          var resultsWidth = $('#results #index-1 a').width();
          var resultsHeight = Math.round(resultsWidth*.66);
          $('#results .asset .thumb').css('height',resultsHeight+'px');
        }
        let pagers = $(".pager");
        $(".pager").on("click", function() {
          $(".active").removeClass("active");
          $(this).addClass("active");
          const index = pagers.index(this);
          const indexnext = pagers.index(this)+2;
          const indexnum = pagers.length;
          $(".assets").removeClass("show").eq(index).addClass("show");

          var resultsWidth = $('#results #'+index+' a').width();
          var resultsHeight = Math.round(resultsWidth*.66);
          $('#results .asset .thumb').css('height',resultsHeight+'px');
          if (index === 0) {
            $('#nav-prev span').hide().removeClass();
          } else {
            $('#nav-prev span').show().removeClass().addClass('index-'+index);
          }
          if (indexnum === index+1) {
            $('#nav-next span').hide().removeClass();
          } else {
            $('#nav-next span').show().removeClass().addClass('index-'+indexnext);
          }
        });
        $("#nav-prev span").on("click", function() {
          $(".active").removeClass("active");
          var prevIndex = $(this).attr('class');
          var prevIndexnum = prevIndex.split('-')[1];
          var activePager = 'pager-'+ prevIndexnum;
          var prevIndexnext = parseInt(prevIndexnum) + 1;
          var prevIndexprev = parseInt(prevIndexnum) - 1;
          // alert(prevIndexnum+ ','+prevIndexnext+ ','+pagernum);
          $('.pager.'+activePager).addClass("active");
          $(".assets").removeClass("show");
          $(".assets#"+prevIndex).addClass("show");
          var resultsWidth = $('.assets#'+ prevIndex +' a').width();
          var resultsHeight = Math.round(resultsWidth*.66);
          $('.asset .thumb').css('height',resultsHeight+'px');
          if (parseInt(prevIndexnum) === 1) {
            $('#nav-prev span').hide().removeClass();
          } else {
            $('#nav-prev span').show().removeClass().addClass('index-'+prevIndexprev);
          }
          $('#nav-next span').show().removeClass().addClass('index-'+prevIndexnext);
        });
        $("#nav-next span").on("click", function() {
          $(".active").removeClass("active");
          var nextIndex = $(this).attr('class');
          var nextIndexnum = nextIndex.split('-')[1];
          var activePager = 'pager-'+ nextIndexnum;
          var nextIndexnext = parseInt(nextIndexnum) + 1;
          var nextIndexprev = parseInt(nextIndexnum) - 1;
          // alert(nextIndexnext+ ','+pagernum);
          $('.pager.'+activePager).addClass("active");
          $(".assets").removeClass("show");
          $(".assets#"+nextIndex).addClass("show");
          $('#nav-prev span').show().removeClass().addClass('index-'+nextIndexprev);
          if (nextIndexnext > pagernum) {
            $('#nav-next span').hide().removeClass();
          } else {
            $('#nav-next span').show().removeClass().addClass('index-'+nextIndexnext);
          }
        });
      } else {
        $('#content-nav').hide();
      }
    } else {
      $('#results').hide();
    }

  }
});

function homeHeader(){
  if ($('body').hasClass('home')){
    var scrollTopHeader = $(window).scrollTop();
    if (scrollTopHeader < 100) {
      $("header").addClass('top0');
    } else {
      $("header").removeClass('top0');
    }
  }
}
$(window).on("load scroll", homeHeader);
// $(window).on('load scroll',function(){
//   if ($('body').hasClass('contact-index')){
//     var hideHead = Math.round($('#application').offset().top);
//     var applicationHeader = $(window).scrollTop();
//     if (applicationHeader < hideHead) {
//       $('body').removeClass('form-header');
//     } else {
//       $('body').addClass('form-header');
//     }
//   }
//   if ($('body').hasClass('online-reservation-index')){
//     var hideHead = Math.round($('#application').offset().top);
//     var applicationHeader = $(window).scrollTop();
//     if (applicationHeader < hideHead) {
//       $('body').removeClass('form-header');
//     } else {
//       $('body').addClass('form-header');
//     }
//   }
// });
$(window).on('load resize',function(){
  if ($('body').hasClass('home')){
    $('#topics .topic').each(function(){
      var topicWidth = $('a',this).width();
      var topicHeight = Math.round(topicWidth*.66);
      $('.thumb',this).css('height',topicHeight+'px');
    });
  }
  if ($('body').hasClass('results-pager')){
    var resultsWidth = $('#results .asset').width();
    console.log(resultsWidth);
    var resultsHeight = Math.round(resultsWidth*.66);
    $('#results .asset .thumb').css('height',resultsHeight+'px');
  }
  if ($('body').hasClass('results-detail')){
    if($(window).width()>767){
      $('#a3DTHETA blockquote').attr('data-height','600');
      // var rightH = $('#detail .section-block').width()*.48*1.333;
      var rightH = $('#detail .section-block img').height();
    } else {
      $('#a3DTHETA blockquote').attr('data-height','320');
      var rightH = $(window).height()*.75;
    }
    var leftH = $('#detail .left').height();
    // alert(rightH +', '+leftH);
    if (rightH < leftH) {
      $('#detail .left .entry-body').height(rightH-60+'px');
      $('#detail .left').css('overflow','hidden');
      $('.entry-body-switch').show();
    }
    $('.entry-body-switch').on('click',function(){
      if($('#detail .left').hasClass('spread')){
        $('#detail .left').removeClass('spread');
        $('#detail .left .entry-body').height(rightH-60+'px');
        $('strong', this).text('続きを見る');
        $('i',this).css('transform','rotateX(0deg)');
      } else {
        $('#detail .left').addClass('spread');
        $('#detail .left .entry-body').css('height','auto');
        $('strong', this).text('もっと少なく');
        $('i',this).css('transform','rotateX(180deg)');
      }
    });
  }
});
